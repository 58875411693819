import './App.css';
import teeleuchte from './assets/teeleuchte.gif'

function App() {
  
  return (
    <div className='main'>
      <h1 className= 'header' >Teeleuchte</h1>
      <img className='teeleuchte' src={teeleuchte}/>
    </div>
    
  );
}

export default App;
